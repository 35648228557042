.subTitle {
    margin-top: 20px;
}

.resultsCard {
    margin-top: 10px;
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
    margin-right: 10px;
    margin-left: 10px;
}

.result {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.title {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
}

.title .open {
    display:inline;
}

.title .closed {
    display:inline;
}

.button {
    text-align: center;
}

.subTitle {
    font-weight: bold;
}

.open {
    color: seagreen;
    font-weight: bold;
}
  
.closed {
    color: lightcoral;
    font-weight: bold;
}

.unknown {
    color: black;
    font-weight: bold;
}
  
.pending {
    color :rgb(114, 148, 128);
    font-weight: bold;
}  

.results {
    vertical-align: top;
    display: inline-flex;
    justify-self: left;
}

.icon {
    font-size: 14px;
    text-align: center;
    padding-right: 10px;
}

.standard-padding {
    padding: 8px;
}

.card-container {
    padding: 24px;
    border-radius: 6px;
    display: block;
}

.result {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.button {
    margin-top: 50px;
    margin-bottom: 30px;
}

.details {
    margin-top: 4px;
    vertical-align: top;
    display: inline-flex;
    justify-self: left;
    margin-left: -8px;
}

.backArrow {
    text-align: left;
}

.loadingAnimation {
    display: flex;
    justify-content: center;
}

.desktopTable {
    margin-top: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    text-transform: capitalize;
}

.mobileTableHeader {
    font-weight: bold;
    display: inline-block;
}

.mobileTable {
    margin-top: 20px;
    margin-bottom: 40px;
    text-transform: capitalize;
}

.historyTable {
    margin-left: 40px;
    margin-right: 40px;
}

.tableHelper {
    font-size: x-large;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: n;
}

.tm {
    height: 10px;
    margin-bottom: 20px;
  }

  .columns {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .pull-left {
    margin-top: -32px;
    margin-left: -20px;
    margin-bottom: -32px;
  }

  .address {
    display: inline-block;
    margin-left: -10px;
  }