table {
  table-layout: fixed;
}

html {
  scroll-behavior: smooth;
}

h2 {
  text-transform: capitalize;
}

h3 {
  text-transform: capitalize;
}

h4 {
  text-transform: capitalize;
}

tr.result td {
  border-bottom: 3px double grey;
}

td {
  padding-top: 5px;
  padding-bottom: 5px;
}

th {
  padding-bottom: 10px;
}

.open {
  color: seagreen;
  font-weight: bold;
}

.pending {
  color: rgb(114, 148, 128);
}

.unknown {
  color: black;
  font-weight: normal;
}

.closed {
  color: lightcoral;
  font-weight: bold;
}

.subheader {
  font-size: small;
  margin-bottom: 30px;
}

.results {
  font-size: 14px;
  text-align: center;
}

.result {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mobileResults {
  padding-top: 20px;
  font-size: 14px;
}

.lineItem {
  margin-top: 30px;
  margin-bottom: 30px;
}

.browse {
  margin-left: 40px;
  margin-right: 40px;
}

.desktopTable {
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
}

.mobileTableHeader {
  font-weight: bold;
  display: inline-block;
  justify-content: center;
}

.mobileTable {
  margin-top: 20px;
  margin-bottom: 40px;
}

tr:hover {
  background-color: rgb(250, 250, 250);
}

.MuiTableSortLabel-root {
  font-weight: bold;
}

.MuiToolbar-root {
  display: flex;
  position: fixed;
  align-items: center;
}

.mobileSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-top: 20px;
  padding-bottom: 25px;
}

.desktopSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-bottom: 24px;
}

.filterBar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

}

.zipCodeFilter {
  padding-right: 4px;
}

.gradeFilter {
  padding-left: 4px;
}

.zipCodeFilterMobile {
  width: 125px;
  padding-right: 4px;
  font-size: 16px;
}

.gradeFilterMobile {
  width: 125px;
  padding-left: 4px;
  font-size: 16px;
}

.tm {
  height: 10px;
  margin-bottom: 20px;
}