.Home {
    text-align: center;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
.Home-header {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  color: black;
}

.sub-header {
  font-size: 2em;
  margin-bottom: 40px;
  font-weight: 400;
}

.mobile-sub-header {
  font-size: 12px;
  margin-bottom: 40px;
  font-weight: 400;
}

h1 { 
  font-family: Pacifico; 
  font-size: 30px; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 400; 
  line-height: 26.4px;
}

h2 {
  font-size: 24px; 
}
  
h3 { 
  font-size: 24px; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 700; 
  line-height: 15.4px; 
}

.bottomText {
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.tm {
  height: 10px;
  margin-bottom: 20px;
}
